import { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import CustomToggle from 'components/Basic/CustomToggle/CustomToggle';
import { FlexDivRow } from './style';
import { toggleGroundMount } from 'store/slices/ToolSlice';
export default memo(function GroundMountToggle() {
	const { groundMountEnabled, newlyCreatedFacets } = useSelector((state: RootState) => state.toolNewPostions.data);
	const toggleRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch<AppDispatch>();
	const toggleChecked = () => {
		if (toggleRef.current) {
			toggleRef.current.click();
			dispatch(toggleGroundMount({ groundMountEnabled: toggleRef.current.checked}));
		}
	};
	const disabled = !!(Object.keys(newlyCreatedFacets).length) ;
	return (
		<FlexDivRow>
			<label>Flat</label>
			<CustomToggle
				ref={toggleRef}
				defaultChecked={groundMountEnabled}
				onChange={toggleChecked}
				styles={{
					height: '25px',
					width: '50px',
				}}
				disabled={disabled}
			/>
		</FlexDivRow>
	);
});