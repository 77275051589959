import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDevMode } from 'store/slices/ToolSlice';

export default function DevModeBtn(){
	const { enabledDevMode} = 
		useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();
	
	const onClickHandler = () => {
		dispatch(setDevMode({enable:!enabledDevMode}));
	};

	return (
		<Button
			style={{
				background: '#FFF',
				color: '#FFF  ',
				borderColor: enabledDevMode ? '#003CFF' : '#C7CACF',
				borderRadius:'20px',
				padding: '0.5rem .2rem'
			}} 
			className='btnHover'
			onClick ={onClickHandler}>
			<svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.9023 1.06067L20.0014 6.76674L14.9023 12.7763" stroke={enabledDevMode ? '#003CFF' : '#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M6.09766 1.06067L0.998615 6.76674L6.09766 12.7763" stroke={enabledDevMode ? '#003CFF' : '#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M12.9581 1L7.98047 12.7764" stroke={enabledDevMode ? '#003CFF' : '#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</Button>
	);
}