import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '../util';
import useScreenDims from './useScreenDims';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { BREAK_POINT_FOR_MOBILE_VIEW, STANDARD_IMAGERY_SIZE } from '../constants';

const useKonvaStageDimensions = ({ stageWrapRef }: { stageWrapRef: MutableRefObject<HTMLDivElement | null> }): Dimensions => {
	const screenDims = useScreenDims();
	const { imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	const [stageDims, setStageDims] = useState<Dimensions>({ height: imgHeight, width: imgWidth });

	const setCanvasDimensionsBasedOnScreen = useCallback(() => {
		const stageWrap = stageWrapRef?.current;
		if (!stageWrap) return;

		let width = STANDARD_IMAGERY_SIZE, height = STANDARD_IMAGERY_SIZE;
		const imageRatio = imgWidth / imgHeight;

		const { width: screenWidth, height: screenHeight } = screenDims;

		// For destop view:
		const isDesktopview = screenWidth > BREAK_POINT_FOR_MOBILE_VIEW;
		// const isTabview = !isDesktopview;

		const maintainAspectRatio = !!org.setting.canEdit && !fullScreenEnabled;
		if (isDesktopview) {
			const rect = stageWrap.getBoundingClientRect();
			width = fullScreenEnabled ? screenWidth : stageWrap.clientWidth;
			height = maintainAspectRatio ? width / imageRatio : screenHeight - rect.top;

		} else if (200 < screenWidth && screenWidth < 500 && !maintainAspectRatio) {
			width = screenWidth;
		}
		setStageDims({ width, height });
	}, [fullScreenEnabled, imgHeight, imgWidth, org.setting.canEdit, screenDims, stageWrapRef]);

	const debouncedUpdateCanvasDimensions = useMemo(() => debounce(setCanvasDimensionsBasedOnScreen, 300),[setCanvasDimensionsBasedOnScreen]);

	useEffect(() => {
		debouncedUpdateCanvasDimensions();
	}, [debouncedUpdateCanvasDimensions]);

	return stageDims;
};

export default useKonvaStageDimensions;